<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Surat Tugas </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputSurattugas">
            <CRow>
              <CCol sm="9">
                <CInput
                  v-model="no_surat_tugas"
                  
                  label="Nomor Surat Tugas"
                  placeholder="Input Nomor Surat Tugas"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_surat_tugas"
                  type="date"
                  label="Tanggal Surat Tugas"
                  placeholder="Input Tanggal Surat Tugas"
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="uraian_surat_tugas"
                  label="Uraian Surat Tugas"
                  placeholder="Input Uraian Surat Tugas"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_pelaksanaan"
                  type="date"
                  label="Tanggal pelaksanaan"
                  placeholder="Input Tanggal pelaksanaan"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="jumlah_hari_pelaksanaan"
                  type="number"
                  label="Jumlah Hari Pelaksanaan"
                  placeholder="Input Jumlah Hari"
                />
              </CCol>              
              <CCol sm="6">
                <CInput
                  v-model="dasar_surat_tugas"
                  label="Dasar Surat Tugas"
                  placeholder="Input Dasar Surat Tugas"
                />
              </CCol>
              <CCol sm="12">
                <div class="form-group">
                <label>Nama Pelaksana</label>
                <multiselect v-model="nama_pelaksana" :options="pegawais" :multiple="true" :taggable="true"></multiselect>
                </div>
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                <label>Pejabat TTD</label>
                  <select v-model="pejabat_ttd" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option v-for="o in pejabats" :value="o.nama_pegawai">{{o.nama_pegawai}}</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                <label>Jenis Tujuan Dinas</label>
                  <select v-model="jenis_tujuan" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1">Dalam Provinsi</option>
                    <option value="2">Luar Provinsi</option>
                    <option value="3">Luar Negeri</option>
                  </select>
                </div>
              </CCol>  
              <!-- <CCol sm="6">             
                <div class="form-group">
                <label>Akun Sakti</label>
                  <select class="form-control" v-model="nama_upt">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option v-for="r in upts" :value="r.id_upt">{{r.nama_upt}} </option>
                  </select>
                </div>
              </CCol>                -->
            </CRow>          
              <div class="form-actions float-right">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/surattugas">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import InputTag from 'vue-input-tag'
import axios from 'axios';
import Multiselect from 'vue-multiselect'
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  components: { 
    InputTag ,
    Multiselect 
  },
  data () {
    return {
      nama_upt : "",
      no_surat_tugas : "",
      tgl_surat_tugas : "",
      uraian_surat_tugas : "",
      tgl_pelaksanaan : "",
      jumlah_hari_pelaksanaan : 0,
      nama_pelaksana : "",
      dasar_surat_tugas : "",
      pejabat_ttd : "",
      jenis_tujuan : "",
      pegawais : [],
      pejabats : [],
      selected: [], // Must be an array reference!
      upts: [],
      show: true,
    }
  },
  mounted(){
    this.loadData();
  },
  
  methods: {

    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"pegawais")
        .then((response) => {
              // this.surattugass=response.data;
              const datas = response.data;
              // this.pejabats = response.data;
              response.data.forEach(obj=> this.pegawais.push(obj.nama_pegawai));
            });

      axios.get(process.env.VUE_APP_BASE_URL+"pegawais/pejabat")
        .then((response) => {
              // this.surattugass=response.data;
              const datas = response.data;
              this.pejabats = response.data;
              // response.data.forEach(obj=> this.pejabats.push(obj.nama_pegawai));
            });

      axios.get(process.env.VUE_APP_BASE_URL+"upts")
        .then((response) => {
              this.upts=response.data;
        });
    },

    inputSurattugas: function(event){

      const formData = new FormData();

        // formData.append('id_upt', this.id_upt)
        formData.append('no_surat_tugas', this.no_surat_tugas)
        formData.append('tgl_surat_tugas', this.tgl_surat_tugas)
        formData.append('uraian_surat_tugas', this.uraian_surat_tugas)
        formData.append('jumlah_hari_pelaksanaan', this.jumlah_hari_pelaksanaan)
        formData.append('tgl_pelaksanaan', this.tgl_pelaksanaan)
        formData.append('nama_pelaksana', this.nama_pelaksana)
        formData.append('dasar_surat_tugas', this.dasar_surat_tugas)
        formData.append('pejabat_ttd', this.pejabat_ttd)
        formData.append('jenis_tujuan', this.jenis_tujuan)
      
      // alert(JSON.stringify(anggota));
      axios.post(process.env.VUE_APP_BASE_URL+"surattugas", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/surattugas');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>